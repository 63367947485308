import * as React from 'react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { Checkbox, IStackProps, IStackStyles, Label, Overlay, ProgressIndicator, Spinner, SpinnerSize, Stack, TextField } from 'office-ui-fabric-react';
import { IPdmsUser } from './IPdmsUser';
//import { ActivityItem, Icon, Link} from 'office-ui-fabric-react';
//import { sp, Web } from '@pnp/sp/presets/all';
//import pnp from 'sp-pnp-js';
//import PnpFileUpload from './FileUploadReact';
import axios from 'axios';

const buttonStyles = { root: { marginRight: 8 } };
const stackTokens = { childrenGap: 50 };
//const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
};

export interface ICheckboxReplyInput{
    Id:number,
    Title?:string,
}

export interface ITransCommentProps {
    siteUrl: string;
    projectNo: string;
    transmittalItemId: string;
    context?: any | null;
    token?: string;
    currentProjectUser: IPdmsUser;
    handleAdd?: any;
    commentMode: string;
    dcCompany: string;
    dcEmails: string;
}

interface ITransCommentState {
    targetListRefresh: boolean;
    added: boolean;
    currentDate: string;
    traID: string;
    isBusy?: boolean;
    progressPercent: number;
    progressLabel: string;
    progressDescription: string;
    showProgress: boolean;
    bigSizeFileNote:string;
    fileSizeLimit:number;
    replyFromEpc?:ITCREPC[];
    replyFromEpcId?:string;
}

export interface ITCREPC
{
    Id?: number;
    Title?: string;
}
let epcOptions: ICheckboxReplyInput[] = [];
// var currentUserId;
// var userEmail : string = 'qq@hotmail.com';
// var currentUserId : number = 2;
// pnp.sp.web.currentUser.get().then(function(res){
//   userEmail = res.Email;
//   currentUserId = res.Id;    
// });
//export const PanelFooterExample : React.FC<Props> = (Props,ITransState) => {

export default class PanelFooterExample extends React.Component<ITransCommentProps, ITransCommentState> {

    constructor(props: ITransCommentProps | Readonly<ITransCommentProps>) {
        var today = new Date(),
            date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
        super(props);
        // sp.setup({
        //   spfxContext: this.props.context
        // });  
        this.state = {
            targetListRefresh: false,
            added: false,
            currentDate: date,
            traID: this.props.transmittalItemId,
            isBusy: false,
            showProgress: false,
            progressPercent: 0,
            progressLabel: "File upload progress",
            progressDescription: "",
            bigSizeFileNote:"",
            fileSizeLimit:0,
        };
        this.traAddHandler = this.traAddHandler.bind(this);
    }

    private traAddHandler(): void {
        this.setState({
            targetListRefresh: true,
            added: true,
        });
    }

    public render(): React.ReactElement<ITransCommentProps> {
        let userName = this.props.currentProjectUser ? this.props.currentProjectUser.name : "";
        let lblcategory;
        if (this.props.commentMode == "TcrComment") {
            lblcategory = "TCR Return";
            return (
                <Stack horizontal tokens={stackTokens} >
                    <Stack {...columnProps}>
                        <Label>Category: </Label> <Label>{lblcategory}</Label>
                        <TextField id="txtSub" label="Subject: " required></TextField>
                        <Label id="txtDate" htmlFor="commentDate">Comment Date: {this.state.currentDate}</Label>
                        <Label id="txtCommentBy">Comment By: {userName}</Label>
                        <Label>Attachment: </Label>
                        {/* <DefaultButton content="Choose File" /> */}
                        {/* <input id="txtfile" type="file"/> */}
                        <form encType="multipart/form-data" id="myForm">
                            <input type="file" name="myFile" id="txtfile" title='upload'/>
                        </form>
                        <Label id="txtNote">{this.state.bigSizeFileNote}</Label>
                        <ProgressIndicator label={this.state.progressLabel} description={this.state.progressDescription} percentComplete={this.state.progressPercent} barHeight={5} />
                        {/* <input ref={this.fileInputRef} type="file" hidden onChange={this.fileChange}/> */}
                        <div>
                            <PrimaryButton onClick={() => this._addComment(this.props) } id="btnSave" styles={buttonStyles}>Save</PrimaryButton>
                            { this.state.isBusy ? <Overlay><Spinner  size={SpinnerSize.large} ></Spinner ></Overlay> : null }
                            {/* <DefaultButton onClick={() =>close()}>Cancel</DefaultButton>   */}
                        </div>
                    </Stack>
                </Stack>
            );
            
        } else {
            lblcategory = "Transmittal Item Return";
            return (
                <Stack horizontal tokens={stackTokens} >
                    <Stack {...columnProps}>
                        <Label>Category: </Label> <Label>{lblcategory}</Label>
                        <TextField id="txtSub" label="Subject: " required></TextField>
                        <TextField id="txtComment" label="Comment: " multiline autoAdjustHeight />
                        <Label id="txtDate" htmlFor="commentDate">Comment Date: {this.state.currentDate}</Label>
                        <Label id="txtCommentBy">Comment By: {userName}</Label>
                        <Label>Attachment: </Label>
                        {/* <DefaultButton content="Choose File" /> */}
                        {/* <input id="txtfile" type="file"/> */}
                        <form encType="multipart/form-data" id="myForm">
                            <input type="file" name="myFile" id="txtfile" title='upload'/>
                        </form>
                        <Label id="txtNote">{this.state.bigSizeFileNote}</Label>
                        <ProgressIndicator label={this.state.progressLabel} description={this.state.progressDescription} percentComplete={this.state.progressPercent} barHeight={5} />
                        {/* <input ref={this.fileInputRef} type="file" hidden onChange={this.fileChange}/> */}
                        <div>
                            <PrimaryButton onClick={() => this._addComment(this.props) } id="btnSave" styles={buttonStyles}>Save</PrimaryButton>
                            { this.state.isBusy ? <Overlay><Spinner  size={SpinnerSize.large} ></Spinner ></Overlay> : null }
                            {/* <DefaultButton onClick={() =>close()}>Cancel</DefaultButton>   */}
                        </div>
                    </Stack>
                </Stack>
            );
        }
    }

    componentDidMount() {

        axios.get('/api/v1/BigSizeFileNote').then((res) => {
          this.setState({
              bigSizeFileNote:res.data.body,
          });
        }).catch(err => 
          {
            console.log("err:" + JSON.stringify(err))
          });

          axios.get('/api/v1/FileSizeLimit').then((res) => {
            this.setState({
                fileSizeLimit:res.data.body,
            });
          }).catch(err => 
            {
              console.log("err:" + JSON.stringify(err))
            });
            this._tcrEPC();
    }

    private _onChange(e:any){      

        var myCheckbox = document.getElementsByName("myCheckbox");
        Array.prototype.forEach.call(myCheckbox,function(el){
            el.checked = false;
        });
        e.target.checked = true;
       let isChecked = e.target.checked;
        var isCheckedId = e.target.id;
        if(isChecked === true){
            this.setState({
                replyFromEpcId: isCheckedId,
            })
        }
      }
    
    private _addComment(traProps: Readonly<ITransCommentProps> & Readonly<{ children?: React.ReactNode; }>) {

        //alert(traProps.projectNo);

        let subject = (document.querySelector("#txtSub") as HTMLInputElement).value;
        //let detail = (document.querySelector("#txtComment") as HTMLInputElement).value;
        let currentUserId = this.props.currentProjectUser.key;
        let replyfromEPcID;
        if(this.state.replyFromEpcId !=null && this.state.replyFromEpcId !=undefined && this.state.replyFromEpcId !=""){
            replyfromEPcID = this.state.replyFromEpcId;
        }else{
            replyfromEPcID = null;
        }
        let particalUrl;
        let detail;
        if (this.props.commentMode === "TcrComment") {
            particalUrl = "tcr";
        } else {
            particalUrl = this.props.commentMode === "Control" ? "transmittalItems" : "nonControlDocTransmittalItems";
            detail = (document.querySelector("#txtComment") as HTMLInputElement).value;
        }
        let request = {
            projectNo: this.props.projectNo,
            title: subject,
            tranId: this.props.transmittalItemId,
            detail: detail,
            currentUserId: currentUserId,
            ReplyFromEPCId: replyfromEPcID
        };
        if (subject !== "") 
        {
            if (this.state.isBusy === false) {
                this.setState({
                    isBusy: true,
                })
            }
            // let particalUrl;
            // if (this.props.commentMode === "TcrComment") {
            //     particalUrl = "tcr";
            // } else {
            //     particalUrl = this.props.commentMode === "Control" ? "transmittalItems" : "nonControlDocTransmittalItems";
            // }
            const config = {
                onUploadProgress: (progressEvent: { loaded: number; total: number; }) => {
                    let { progressPercent } = this.state;
                    progressPercent = (progressEvent.loaded / progressEvent.total); //* 100;
                    this.setState({
                        progressPercent,
                        progressDescription: `${Math.round(progressPercent*100)} %`
                    });
                }
            }

            axios.post(`/api/v1/${particalUrl}/addComment`, request).then((d) => {
                //console.log(d);
                //debugger;
                let commentId = d.data.body.data.Id;

            if ((document.querySelector("#txtfile") as HTMLInputElement).files!.length > 0)
            {
                    //upload the file as the attachment
                    let myfile = (document.querySelector("#txtfile") as HTMLInputElement).files![0];
                    if (myfile.size > this.state.fileSizeLimit)
                    {
                        alert("File size limit to : " + (this.state.fileSizeLimit/(1024*1024*1024)).toString() + " GB" );
                        this.setState({
                            isBusy: false,
                        })
                        return false;
                    }
                    let fname = myfile.name.substring(0, myfile.name.lastIndexOf('.'));
                    let fileType = myfile.name.replace(fname, "");
                    let newFIleName = `${fname}(comment-${commentId})${fileType}`;
                    let newbigFIleName = `${fname}`; 
                    let myForm = document.getElementById('myForm') as HTMLFormElement;
                    let formData = new FormData(myForm);
                    axios.post(`/api/v1/fileUpload?filename=${encodeURIComponent(newFIleName)}&projectno=${this.props.projectNo}&newbigFileName=${newbigFIleName}&fileType=${fileType}&itemBindID=${commentId}&libraryname=Attachments`, formData,config).then((f) => {
                    console.log(`f=${f}`);
                    let attachmentId = f.data.fileId;
                    //update the metadata for attachment
                    let oRequest = {
                        commentId: commentId,
                        attachmentId: attachmentId,
                        projectNo: this.props.projectNo,
                    };
                    //#region check whether is is a big size file by using attachmentId, 0 means bigsize file
                    if (attachmentId !== 0)
                    {
                        axios.post(`/api/v1/transmittalItems/updateAttachment`, oRequest).then(() => {
                        if (this.props.commentMode === "TcrComment") {
                            let cRequest = {
                                tcrId: this.props.transmittalItemId,
                                projectNo: this.props.projectNo,
                                commentId: commentId,
                                dcEmails: this.props.dcEmails,
                            }
                            let requestTcr = {
                                projectNo: this.props.projectNo,
                                tcrId:this.props.transmittalItemId
                            }
                            // axios.post(`/api/v1/tcr/tqUpdate`,requestTcr).then((tq) =>{
                                axios.post(`/api/v1/tcr/commentNotification`, cRequest).then(() => {
                                this.props.handleAdd();
                                }).catch((ex) => 
                                {
                                    alert("Comment added but notification failed.")
                                    this.props.handleAdd();
                                });                               
                            // }).catch((ex) => 
                            // {
                            //     alert("Comment added but TQ document update failed.")
                            //     this.props.handleAdd();
                            // }); 
                        }
                        else {
                            this.props.handleAdd();
                        }
                    });
                    this.setState({
                        isBusy: false,
                    })
                    }
                    else
                    {
                        if (this.props.commentMode === "TcrComment") {
                            let cRequest = {
                                tcrId: this.props.transmittalItemId,
                                projectNo: this.props.projectNo,
                                commentId: commentId,
                                dcEmails: this.props.dcEmails,
                            }
                            let requestTcr = {
                                projectNo: this.props.projectNo,
                                tcrId:this.props.transmittalItemId
                            }
                            // axios.post(`/api/v1/tcr/tqUpdate`,requestTcr).then((tq) =>{
                                axios.post(`/api/v1/tcr/commentNotification`, cRequest).then(() => {
                                    this.props.handleAdd();
                                    this.setState({
                                        isBusy: false,
                                        progressLabel: `Note: Displaying may delay because big size file detected.`,
                                        progressDescription:`File upload complete`,
                                    })
                                }).catch((ex) => 
                                {
                                    alert("Comment added but notification failed.")
                                    this.props.handleAdd();
                                    this.setState({
                                        isBusy: false,
                                        progressLabel: `Note: Displaying may delay because big size file detected.`,
                                        progressDescription:`File upload complete`,
                                    })
                                });                               
                            // }).catch((ex) => 
                            // {
                            //     alert("Comment added but TQ document update failed.")
                            //     this.props.handleAdd();
                            // }); 
                        }
                        else {
                            this.props.handleAdd();
                        }

                        // this.props.handleAdd();
                        // this.setState({
                        //     isBusy: false,
                        //     progressLabel: `Note: Displaying may delay because big size file detected.`,
                        //     progressDescription:`File upload complete`,
                        // })
                    }
                    //#endregion
                });
            }
            else
            {
                //alert('Comment Added');
                if (this.props.commentMode === "TcrComment") 
                {
                    let requestTcr = {
                        projectNo: this.props.projectNo,
                        tcrId:this.props.transmittalItemId
                    };
                    // axios.post(`/api/v1/tcr/tqUpdate`,requestTcr).then((tq) =>{
                        let cRequest = {
                            tcrId: this.props.transmittalItemId,
                            projectNo: this.props.projectNo,
                            commentId: commentId,
                            dcEmails: this.props.dcEmails,
                        }
                        axios.post(`/api/v1/tcr/commentNotification`, cRequest).then(() => {
                            this.props.handleAdd();
                            this.setState({
                                isBusy: false,
                            })  
                        }).catch((ex) => {
                            alert("Comment added but notification failed.")
                            this.props.handleAdd();
                            this.setState({
                                isBusy: false,
                            })
                        });                            
                    // }).catch((ex) => 
                    // {
                    //     alert("Comment added but TQ document update failed.")
                    //     this.props.handleAdd();
                    // });
                    
                }
                else 
                {
                    this.props.handleAdd();
                    this.setState({
                        isBusy: false,
                    })
                }
            }
        })
}
else{
    alert("Subject field is mandatory");
}
    // let subject = (document.querySelector("#txtSub") as HTMLInputElement).value; 
    // var commentData = (document.querySelector("#txtComment") as HTMLInputElement).value;//document.getElementById("txtComment").nodeValue;

    // let w = Web(this.props.siteUrl + "/" + this.props.projectNo);
    // let id = this.state.traID;//.transmittalItemId;
    // alert(id);
    // w.lists.getByTitle("Comments").items.add({
    //   Item_x0020_Bind_x0020_Id: traProps.transmittalItemId,
    //   Category:"Transmittal Item Return",
    //   Title: subject,
    //   Comments:commentData,
    //   Comments_x0020_Date: new Date(),
    //   CommentById: this.props.currentProjectUser.key,
    //   //Description: "Description"
    // }).then((comData)=>{
    //   let commentId = comData.data.Id;
    //   this._fileUpload(commentId);
    // });
    
  }
  private _fileUpload(commentId: any){
    // let myfile = (document.querySelector("#txtfile") as HTMLInputElement).files![0];  
    // var rUrl = this.props.context.pageContext.web.serverRelativeUrl + "/" + this.props.projectNo + "/Attachments";
    // let web = Web(this.props.siteUrl + "/" + this.props.projectNo);
    // web.getFolderByServerRelativeUrl(rUrl).files.add(myfile.name, myfile, true).
    //   then((result)=> {        
    //     alert(myfile.name + " upload successfully!");
    //     console.log("File Uploaded");
    //     result.file.listItemAllFields.get().then((listItemAllFields) => {
    //       // get the item id of the file and then update the columns(properties)
    //       web.lists.getByTitle("Attachments").items.getById(listItemAllFields.Id).update({
    //         Item_x0020_Bind_x0020_ID: commentId,//this.state.traID,
    //         //Description: "Here is a new description"
    //       }).then(r=>{
    //         console.log(myfile.name + " properties updated successfully!");
    //       });
    //     });
    // });
}  
private async _tcrEPC () {
    if(this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      })
    }
    let clas : ITCREPC = {
    };
    await axios.get(`/api/v1/tcr/getReplyFromEPC?projectNo=${this.props.projectNo}`).then((clsdata)=>{
      //clsdata
      epcOptions = [];
      let epcData = clsdata.data.body;
      let _replyfromEPC = epcData.map((xx:{Title: string, Id:number})=>{
        epcOptions.push({
          Id: xx.Id,
          Title:xx.Title
        });      
    });

    this.setState({
      replyFromEpc:_replyfromEPC,
      isBusy:false,
    });

  });

    return clas;

  }
}


